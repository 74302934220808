import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { toggleAsideMenu } from "./menuActionCreators";
import { logout } from "app/pages/Auth/AuthActions";
import Header from "@brand/Header/Header";
import { bindActionCreators } from "redux";
import { brandPropTypes, callCenterShape } from "app/utils/propTypes";
import { connect } from "react-redux";
import { resetAllProductsFilter } from "app/pages/Listing/ProductFilters/productsFilterActionCreators";
import { hideAsideProductFilters } from "app/pages/.shared/AsidePanel/AsideProductFilters/AsideProductFiltersActionCreators";
import { isAnyListingFilterActive } from "app/pages/Listing/productsSelector";
import { getContextCallCenter } from "app/pages/Booking/bookingSelectors";
import { useHistory, withRouter } from "react-router-dom";
import { isWhiteLabel } from "app/reducers/brandSelector";
import get from "lodash/get";
import { isAuthenticated } from "app/pages/Auth/authSelectors";
import MarketingBanner from "app/pages/Header/MarketingBanner/MarketingBanner";
import {
	getAvailableCreditNotesTotal,
	getAvailableCreditsTotal,
	hasAnyCreditNotes,
} from "app/pages/Account/MyCoupons/couponSelector";
import CouponsBannerContainer from "app/pages/.shared/CouponsBanner/CouponsBannerContainer";
import { getOpenCustomerRequestsCount } from "app/pages/Account/MyBookings/myBookingsSelector";
import {
	getFallbackRedirectUrl,
	shouldUseBrowserBackOnProductPage,
} from "app/reducers/partnerSelector.js";

const HeaderContainer = props => {
	const { showSmartDPOnRootUrl, menu, isInApp, logout, marketingBanner, ...restProps } = props;
	const history = useHistory();

	const handleLogoutClick = useCallback(() => {
		logout();
		history.push({ pathname: "/" });
	}, [showSmartDPOnRootUrl]);

	return (
		!isInApp && (
			<React.Fragment>
				<Header {...menu} logoutClick={handleLogoutClick} {...restProps} />
				<CouponsBannerContainer />
				<MarketingBanner
					launchAt={marketingBanner.launchAt}
					endAt={marketingBanner.endAt}
					textColor={marketingBanner.textColor}
					backgroundColor={marketingBanner.backgroundColor}
					message={marketingBanner.message}
					pages={marketingBanner.pages}
					promoCode={marketingBanner.promoCode}
				/>
			</React.Fragment>
		)
	);
};

HeaderContainer.propTypes = {
	showSmartDPOnRootUrl: PropTypes.bool,
	userIsConnected: PropTypes.bool,
	callCenter: callCenterShape,
	partnercode: PropTypes.string,
	menu: PropTypes.object,
	route: PropTypes.object,
	helpContactUrl: PropTypes.string,
	dialPhone: PropTypes.string,
	toggleAsideMenu: PropTypes.func,
	hideAsideProductFilters: PropTypes.func,
	resetAllProductsFilter: PropTypes.func,
	logout: PropTypes.func,
	brand: brandPropTypes,
	isAnyListingFilterActive: PropTypes.bool,
	enableSponsorship: PropTypes.bool,
	history: PropTypes.object,
	marketingBanner: PropTypes.object,
	creditNotesAmount: PropTypes.number,
	merchandisingCode: PropTypes.string,
	isInApp: PropTypes.bool,
};

HeaderContainer.defaultProps = {
	marketingBanner: {},
};

const mapStateToProps = (state, ownProps) => {
	const isConfirmationPage = ownProps.match.url === "/booking/confirmation";
	const contextCallCenter = getContextCallCenter({ isConfirmationPage })(state);
	const partner = state.partner;

	return {
		userIsConnected: isAuthenticated(state),
		resolution: state.resolution,
		menu: state.menu,
		shop: state.shop,
		brand: state.brand.code,
		partnercode: partner.code,
		showSmartDPOnRootUrl: partner.showSmartDPOnRootUrl,
		isWhiteLabel: isWhiteLabel(state),
		helpContactUrl: contextCallCenter.helpContactUrl,
		displayPhone: contextCallCenter.displayPhone,
		dialPhone: contextCallCenter.dialPhone,
		callPrice: contextCallCenter.callPrice,
		isAnyListingFilterActive: isAnyListingFilterActive(state),
		enableSponsorship: partner.enableSponsorship,
		enableCredits: partner.enableCredits,
		merchandisingCode: get(state, "merchandising.current.code"),
		isInApp: state.navigation.isInApp,
		marketingBanner: partner.marketingBanner,
		creditsAmount: getAvailableCreditsTotal(state),
		creditNotesAmount: getAvailableCreditNotesTotal(state),
		showCreditsNotification: state.coupons.showCreditsNotification,
		showCreditNotesNotification: state.coupons.showCreditNotesNotification,
		shouldDisplayCreditNotesEntry: hasAnyCreditNotes(state),
		openCustomerRequestCount: getOpenCustomerRequestsCount(state),
		fallbackRedirectUrl: getFallbackRedirectUrl(state),
		useBrowserBackOnProductPage: shouldUseBrowserBackOnProductPage(state),
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			logout,
			toggleAsideMenu,
			hideAsideProductFilters,
			resetAllProductsFilter,
		},
		dispatch
	);
};

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(HeaderContainer)
);
