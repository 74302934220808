import React from "react";
import PropTypes from "prop-types";
import { useRouteMatch } from "react-router-dom";
import "./MarketingBanner.scss";

const MarketingBanner = ({
	message,
	backgroundColor,
	textColor,
	pages,
	launchAt,
	endAt,
	promoCode,
}) => {
	const today = new Date();
	const endDate = new Date(endAt);
	const launchDate = new Date(launchAt);

	const checkRoute = pages.find(page => {
		const match = useRouteMatch();
		return (
			match.path.includes(page) || (page === "product" && match.path.includes(":productUri"))
		);
	});

	const shouldDisplayBanner = checkRoute && today >= launchDate && today < endDate && message;

	return shouldDisplayBanner ? (
		<div className="marketing-banner" style={{ background: backgroundColor, color: textColor }}>
			{promoCode && <span className="marketing-banner__promocode">{promoCode}</span>}
			<span
				dangerouslySetInnerHTML={{
					__html: message,
				}}
			/>
		</div>
	) : null;
};

MarketingBanner.propTypes = {
	message: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.string),
		PropTypes.string,
		PropTypes.element,
	]),
	backgroundColor: PropTypes.string,
	textColor: PropTypes.string,
	pages: PropTypes.array,
	launchAt: PropTypes.string,
	endAt: PropTypes.string,
	promoCode: PropTypes.string,
};

MarketingBanner.defaultProps = {
	pages: [],
};

export default React.memo(MarketingBanner);
