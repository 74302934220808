import React from "react";
import PropTypes from "prop-types";
import List from "app/pages/.shared/List";
import { FormattedMessage } from "react-intl";
import IconInfo from "app/pages/.shared/static/icons/IconInfo";
import PopoverButton from "app/pages/.shared/Popover/PopoverButton";
import "./CouponsCGUPopoverButton.scss";

const CouponsCguPopoverButton = ({
	hasCredits,
	hasCreditNotes,
	isPromotionCodeEnabled,
	isDetailed,
	onClick,
}) => {
	return (
		<PopoverButton
			popOverClassName="coupons-cgu-popover-button"
			popOverButtonClassName="coupons-cgu-popover-button__button"
			popOverPosition="below"
			closeOnBodyClick={true}
			onClick={onClick}
			popOverContent={
				<div className="coupons-cgu-popover-button__content">
					{hasCredits && isPromotionCodeEnabled && (
						<div className="coupons-cgu-popover-button__item">
							<div className="coupons-cgu-popover-button__title">
								<FormattedMessage id="payment.coupons.cgu.general.title" />
							</div>
							<List
								items={[
									<FormattedMessage
										key="payment.coupons.cgu.general.1"
										id="payment.coupons.cgu.general.1"
									/>,
								]}
							/>
						</div>
					)}
					{hasCredits && (
						<div className="coupons-cgu-popover-button__item">
							<div className="coupons-cgu-popover-button__title">
								<FormattedMessage id="payment.coupons.cgu.credits.title" />
							</div>
							<List
								items={[
									<FormattedMessage
										id="payment.coupons.cgu.credits.1"
										key="payment.coupons.cgu.credits.1"
									/>,
									<FormattedMessage
										id="payment.coupons.cgu.credits.2"
										key="payment.coupons.cgu.credits.2"
									/>,
									<FormattedMessage
										id="payment.coupons.cgu.credits.3"
										key="payment.coupons.cgu.credits.3"
									/>,
									<FormattedMessage
										id="payment.coupons.cgu.credits.4"
										key="payment.coupons.cgu.credits.4"
									/>,
								]}
							/>
						</div>
					)}
					{hasCreditNotes && !isDetailed && (
						<div className="coupons-cgu-popover-button__item">
							<List
								items={[
									<FormattedMessage
										id="payment.coupons.cgu.refunds.1"
										key="payment.coupons.cgu.refunds.1"
									/>,
									<FormattedMessage
										id="payment.coupons.cgu.refunds.2"
										key="payment.coupons.cgu.refunds.2"
									/>,
									<FormattedMessage
										id="payment.coupons.cgu.refunds.3"
										key="payment.coupons.cgu.refunds.3"
									/>,
									<FormattedMessage
										id="payment.coupons.cgu.refunds.4"
										key="payment.coupons.cgu.refunds.4"
									/>,
									<FormattedMessage
										id="payment.coupons.cgu.refunds.5"
										key="payment.coupons.cgu.refunds.5"
									/>,
									<FormattedMessage
										id="payment.coupons.cgu.refunds.6"
										key="payment.coupons.cgu.refunds.6"
									/>,
								]}
							/>
						</div>
					)}
					{hasCreditNotes && isDetailed && (
						<div className="coupons-cgu-popover-button__item">
							<div className="coupons-cgu-popover-button__title">
								<FormattedMessage id="payment.coupons.cgu.refunds.banner.1" />
							</div>
						</div>
					)}
					{isPromotionCodeEnabled && (
						<div className="coupons-cgu-popover-button__item">
							<div className="coupons-cgu-popover-button__title">
								<FormattedMessage id="payment.coupons.cgu.promo.title" />
							</div>
							<List
								items={[
									<FormattedMessage
										id="payment.coupons.cgu.promo.1"
										key="payment.coupons.cgu.promo.1"
									/>,
									<FormattedMessage
										id="payment.coupons.cgu.promo.2"
										key="payment.coupons.cgu.promo.2"
									/>,
								]}
							/>
						</div>
					)}
				</div>
			}
			buttonLabel={<IconInfo width={15} height={15} style={{ marginLeft: "5px" }} />}
		/>
	);
};

CouponsCguPopoverButton.propTypes = {
	hasCredits: PropTypes.bool,
	hasCreditNotes: PropTypes.bool,
	isPromotionCodeEnabled: PropTypes.bool,
	isDetailed: PropTypes.bool,
	onClick: PropTypes.func,
};

CouponsCguPopoverButton.defaultProps = {
	onClick: () => {},
};

export default React.memo(CouponsCguPopoverButton);
