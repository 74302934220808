import React from "react";
import PropTypes from "prop-types";
import "./Header.scss";
import LogoBrandContainer from "app/pages/.shared/LogoBrand/LogoBrandContainer";
import { FormattedMessage } from "react-intl";

/* paths also equal to part of the translation key */
const PATHS = ["default", "fashion", "sport", "home", "kids", "travel"];

class Header extends React.PureComponent {
	getShopInfo() {
		const { shop } = this.props;

		const language = shop.slice(0, 2);
		const country = shop.slice(3, 5);

		return {
			language,
			country,
			isGerman: language === "de",
			isEBoutic: country === "CH",
			lowerCase: shop.toLowerCase(),
		};
	}

	getUrlInfo(shopInfo) {
		if (shopInfo.isEBoutic) {
			return {
				domain: "https://www.eboutic.ch",
				pathPrefix: "/sales",
				pathSuffix: "",
			};
		}

		return {
			domain: `https://secure.${shopInfo.language}.vente-privee.com`,
			pathPrefix: `/ns/${shopInfo.lowerCase}/home`,
			pathSuffix: "/classic",
		};
	}

	getHamburgerElements(shopInfo) {
		let hamburgerList;

		/* link definitions */
		if (shopInfo.isEBoutic) {
			hamburgerList = {
				default: {
					url: "https://www.eboutic.ch/sales", // Same link as submenu
					msgId: "header.vp.home",
					iconClass: "icon-vp-home vp",
				},
				account: {
					url: "https://www.eboutic.ch/account",
					msgId: "header.vp.account",
					iconClass: "icon-vp-account vp",
				},
				orders: {
					url: "https://www.eboutic.ch/orders?utm_source=PerfectStay",
					msgId: "header.vp.bookings",
					iconClass: "icon-vp-order vp",
				},
				sponsorship: {
					url: "https://www.eboutic.ch/account/invite",
					msgId: "header.vp.sponsorship",
					iconClass: "icon-vp-sponsorship vp",
				},
				help: {
					url: shopInfo.isGerman
						? "https://www.eboutic.ch/de/kontakt"
						: "https://www.eboutic.ch/fr/contact",
					msgId: "header.vp.help",
					iconClass: "icon-vp-help vp",
				},
			};
		} else {
			hamburgerList = {
				default: {
					url: `https://secure.${shopInfo.language}.vente-privee.com/ns/${
						shopInfo.lowerCase
					}/home/default`, // Same link as submenu
					msgId: "header.vp.home",
					iconClass: "icon-vp-home vp",
				},
				account: {
					url: `https://secure.${
						shopInfo.language
					}.vente-privee.com/memberaccount/memberaccount`,
					msgId: "header.vp.account",
					iconClass: "icon-vp-account vp",
				},
				orders: {
					url: `https://secure.${
						shopInfo.language
					}.vente-privee.com/memberaccount/order?utm_source=PerfectStay`,
					msgId: "header.vp.bookings",
					iconClass: "icon-vp-order vp",
				},
				sponsorship: {
					url: `https://secure.${shopInfo.language}.vente-privee.com/ns/${
						shopInfo.lowerCase
					}/sponsorship`,
					msgId: "header.vp.sponsorship",
					iconClass: "icon-vp-sponsorship vp",
				},
				help: {
					url: `https://secure.${
						shopInfo.language
					}.vente-privee.com/VP4/Help/Default.aspx`,
					msgId: "header.vp.help",
					iconClass: "icon-vp-help vp",
				},
			};
		}

		/* create hamburger elements depending on the shop and the key (semi-dynamic) */
		return Object.keys(hamburgerList).map(key => (
			<a key={key} className="dropdown-item" href={hamburgerList[key].url}>
				<span>
					<i className={hamburgerList[key].iconClass} />
				</span>
				<FormattedMessage id={hamburgerList[key].msgId} />
			</a>
		));
	}

	getSubmenuElements(shopInfo) {
		const urlInfo = this.getUrlInfo(shopInfo);

		/* create submenu URLS depending on the shop and the key (dynamic) */
		const submenuUrls = PATHS.reduce((accumulator, currentValue) => {
			accumulator[currentValue] = `${urlInfo.domain}${urlInfo.pathPrefix}/${currentValue}${
				urlInfo.pathSuffix
			}`;
			return accumulator;
		}, {});

		return Object.keys(submenuUrls).map(key => (
			<li key={key} className="header__submenu-item">
				<a href={submenuUrls[key]}>
					<FormattedMessage id={`subheader.${key}`} />
				</a>
			</li>
		));
	}

	render() {
		const shopInfo = this.getShopInfo();
		const hamburgerElements = this.getHamburgerElements(shopInfo);
		const submenuElements = this.getSubmenuElements(shopInfo);

		const { fallbackRedirectUrl } = this.props;

		return (
			<header className="header">
				<div className="header__main">
					<div className="header__hamburger">
						<div id="header-menu" className="">
							<div id="menu-button" className="hidden-xs-down">
								<span />
								<span />
								<span />
							</div>
							<div className="text hidden-sm-down">
								<FormattedMessage id="header.vp.menu" />
							</div>
							<div className="dropdown-menu hidden-xs-down">{hamburgerElements}</div>
						</div>
					</div>
					<div className="header__logo">
						<a href={fallbackRedirectUrl}>
							<LogoBrandContainer />
						</a>
					</div>
					<div className="header__menu">
						{shopInfo.isEBoutic && (
							<React.Fragment>
								<a
									className="header__menu-item"
									href="https://www.eboutic.ch/account/invite"
								>
									<i className="icon-vp-sponsorship" />
									<FormattedMessage id="parrainnage.title" />
								</a>
								<a
									className="header__menu-item"
									href={
										shopInfo.isGerman
											? "https://www.eboutic.ch/de/wer-sind-wir"
											: "https://www.eboutic.ch/fr/qui-sommes-nous"
									}
								>
									<i className="icon-vp-about" />
									<FormattedMessage id="header.eboutic.about" />
								</a>
							</React.Fragment>
						)}
					</div>
				</div>

				<nav className="header__secondary">
					<ul className="header__submenu">{submenuElements}</ul>
				</nav>
			</header>
		);
	}
}

Header.propTypes = {
	shop: PropTypes.string.isRequired,
	fallbackRedirectUrl: PropTypes.string,
};

export default Header;
